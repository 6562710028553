<template>
  <div class="shiping">
    <el-button type="primary" @click="claickdialogVisible"
      >新增收货地址</el-button
    >
    <div class="shipList" v-for="(item, index) in allList" :key="index">
      <div class="guanbi" @click="removelist(item.id)">x</div>
      <div class="lsittop">
        <span>{{ item.name }}</span>
        <div class="cards" v-if="item.isDefault">默认地址</div>
      </div>
      <div class="texts">
        <span class="textneme">收货人：</span
        ><span class="center">{{ item.name }}</span>
      </div>
      <div class="texts">
        <span class="textneme">所在地区：</span
        ><span class="center">{{ item.consigneeAddressPath }}</span>
      </div>
      <div class="texts">
        <span class="textneme">详细地址：</span
        ><span class="center">{{ item.detail }}</span>
      </div>
      <div class="texts">
        <span class="textneme">手机号：</span
        ><span class="center">{{ item.mobile }}</span>
      </div>
      <div class="bottoms">
        <div class="moren" v-if="!item.isDefault" @click="endsclick(item, 5)">
          设为默认地址
        </div>
        <div class="edter" @click="endsclick(item)">
          <img class="icons" src="../../assets/img/bianji.png" alt="" />编辑
        </div>
      </div>
    </div>
    <el-dialog
      :title="titles"
      :visible.sync="dialogVisible"
      width="30%"
      center
      :show-close="false"
    >
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="收货人" prop="text">
          <el-input
            type="text"
            v-model="ruleForm.text"
            placeholder="请输入收货人"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="所在地区">
          <el-cascader
            style="width: 100%"
            v-model="ruleForm.procen"
            placeholder="请选择省市区"
            :options="cascaderData"
            :separator="','"
            @change="changeclick"
            @active-item-change="handleItemChange"
            :props="{
              value: 'id',
              label: 'name',
              children: 'cities',
            }"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="infos">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入详细地址"
            v-model="ruleForm.infos"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input
            type="text"
            v-model="ruleForm.phone"
            placeholder="请输入手机号"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  postmemberAddress,
  getallCity,
  getmemberAddress,
  putmemberAddress,
  deletememberAddress,
} from "../../request/homeApi";
export default {
  data() {
    var checkAge = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("手机号不能为空"));
      }
      var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!myreg.test(value)) {
        callback(new Error("请输入正确手机号"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入收货人"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入详细地址"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        text: "",
        procen: [],
        phone: "",
        infos: "",
      },
      titles: "添加收货地址",
      rules: {
        text: [{ validator: validatePass, trigger: "blur" }],
        phone: [{ validator: checkAge, trigger: "blur" }],
        infos: [{ validator: validatePass2, trigger: "blur" }],
      },
      id: "",
      dialogVisible: false,
      cascaderData: [],
      allList: [],
      procen: [],
      positionid: 0,
      memberId: "",
      lon: [], //进度
      lat: [], //纬度
      loals: [],
      consigneeAddressPath: [], //，地名
      pamas: {
        pageNumber: 1,
        pageSize: 10,
      },
    };
  },
  mounted() {
    this.getNodes();
    this.listads();
  },
  methods: {
    listads() {
      getmemberAddress(this.pamas)
        .then((res) => {
          if (res.code == 200) {
            this.allList = res.result.records;
          }
        })
        .catch((res) => {
          console.log("请求出错");
        });
    },
    getNodes(val, indexs) {
      let idArea;
      let sizeArea;
      if (!val) {
        idArea = 0;
        sizeArea = 0;
      } else if (val.length === 1) {
        idArea = val[0];
        sizeArea = val.length;
      } else if (val.length === 2) {
        idArea = val[1];
        sizeArea = val.length;
      } else if (val.length === 3) {
        idArea = val[2];
        sizeArea = val.length;
      }
      getallCity(idArea).then((res) => {
        if (res.code == 200) {
          let Items = res.result;
          if (sizeArea === 0) {
            // 初始化 加载一级 省  dfasdf
            this.cascaderData = Items.map((value, i) => {
              return {
                id: value.id,
                center: value.center,
                name: value.name,
                cities: [],
              };
            });
          } else if (sizeArea === 1) {
            // 点击一级 加载二级 市
            this.cascaderData.map((value, i) => {
              if (value.id === val[0]) {
                if (!value.cities.length) {
                  value.cities = Items.map((value, i) => {
                    return {
                      id: value.id,
                      center: value.center,
                      name: value.name,
                      cities: [],
                    };
                  });
                }
              }
            });
          } else if (sizeArea === 2) {
            // 点击二级 加载三级 区
            this.cascaderData.map((value, i) => {
              if (value.id === val[0]) {
                value.cities.map((value, i) => {
                  if (value.id === val[1]) {
                    if (!value.cities.length) {
                      if (Items.length > 0) {
                        value.cities = Items.map((value, i) => {
                          return {
                            id: value.id,
                            center: value.center,
                            name: value.name,
                          };
                        });
                      } else {
                        delete value.cities;
                      }
                    }
                  }
                });
              }
            });
          } else if (sizeArea === 3) {
            // 镇ddddd
            if (Items.length > 0) {
              // console.log("垃圾sss");
              this.cascaderData.map((value, i) => {
                if (value.id === val[0]) {
                  value.cities.map((value, i) => {
                    if (value.id === val[1]) {
                      value.cities.map((value, i) => {
                        if (value.id === val[2]) {
                          if (!value.cities.length) {
                            value.cities = Items.map((value, i) => {
                              return {
                                id: value.id,
                                center: value.center,
                                name: value.name,
                              };
                            });
                          }
                        }
                      });
                    }
                  });
                }
              });
            } else {
              // this.cascaderData.map((value, i) => {
              //   if (value.id === val[0]) {
              //     value.cities.map((value, i) => {
              //       value.cities.map((value, i) => {
              //         console.log(value)
              //         delete value.cities
              //          this.$forceUpdate()
              //         return
                     
              //       });
              //     });
              //   }
              // });
            }
          }
          if (indexs == 2) {
            that.lat = [];
            that.lon = [];
          }
        } else {
          console.log(response.data.msg);
        }
      });
    },
    handleItemChange(val) {
      this.getNodes(val);
    },
    changeclick(val, indexs) {
      this.consigneeAddressPath = [];
      this.cascaderData.map((value, i) => {
        if (value.id === val[0]) {
          this.loals.push(value.center);
          this.consigneeAddressPath.push(value.name);
          value.cities.map((value, i) => {
            if (value.id === val[1]) {
              this.loals.push(value.center);
              this.consigneeAddressPath.push(value.name);
              value.cities.map((value, i) => {
                if (value.id === val[2]) {
                  this.loals.push(value.center);
                  this.consigneeAddressPath.push(value.name);
                  value.cities.map((value, i) => {
                    if (value.id === val[3]) {
                      this.loals.push(value.center);
                      this.consigneeAddressPath.push(value.name);
                    }
                  });
                }
              });
            }
          });
        }
      });

      this.loals.forEach((item) => {
        item = item.split(",");
        this.lon.push(item[0]);
        this.lat.push(item[1]);
      });
      if (indexs == 2) {
        this.loals = [];
        that.lat = [];
        that.lon = [];
      }
    },
    submitForm() {
      let that = this;
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let obj = {
            alias: "",
            id: that.id,
            memberId: this.memberId,
            consigneeAddressIdPath: that.ruleForm.procen.join(","),
            consigneeAddressPath: that.consigneeAddressPath.join(","),
            lon: that.lon.join(","),
            lat: that.lat.join(","),
            name: that.ruleForm.text,
            mobile: that.ruleForm.phone,
            detail: that.ruleForm.infos,
          };
          if (this.id) {
            putmemberAddress(obj)
              .then((res) => {
                if (res.code == 200) {
                  this.$message.success("操作成功！");
                  this.listads();
                }
              })
              .catch((res) => {
                console.log("请求出错");
              });
          } else {
            postmemberAddress(obj)
              .then((res) => {
                if (res.code == 200) {
                  this.$message.success("操作成功！");
                  this.listads();
                }
              })
              .catch((res) => {
                console.log("请求出错");
              });
          }

          that.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    // 删除
    removelist(id) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deletememberAddress(id)
            .then((res) => {
              if (res.code == 200) {
                this.$message.success("操作成功！");
                this.listads();
              }
            })
            .catch((res) => {
              console.log("请求出错");
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 编辑/设置默认值
    endsclick(item, index) {
      let that = this;
      that.titles = "编辑收货地址";
      that.id = item.id;
      that.ruleForm.text = item.name;
      that.ruleForm.procen = item.consigneeAddressIdPath.split(",");
      that.consigneeAddressPath = item.consigneeAddressPath.split(",");
      that.ruleForm.phone = item.mobile;
      that.ruleForm.infos = item.detail;
      that.memberId = item.memberId;
      that.lat = [];
      that.lon = [];
      if (index != 5) {
        if (that.ruleForm.procen.length > 0) {
          that.getNodes([that.ruleForm.procen[0]], 2);
          setTimeout((items) => {
            that.getNodes(
              [that.ruleForm.procen[0], that.ruleForm.procen[1]],
              2
            );
          }, 500);
          setTimeout((items) => {
            that.getNodes(
              [
                that.ruleForm.procen[0],
                that.ruleForm.procen[1],
                that.ruleForm.procen[2],
              ],
              2
            );
          }, 800);
          setTimeout((items) => {
            that.changeclick(that.ruleForm.procen, 2);
          }, 1000);
        }
        that.dialogVisible = true;
      } else {
        let obj = {
          isDefault: true,
          id: item.id,
          consigneeAddressIdPath: item.consigneeAddressIdPath,
          consigneeAddressPath: item.consigneeAddressPath,
          lon: item.lon,
          lat: item.lat,
          name: item.name,
          mobile: item.mobile,
          detail: item.detail,
          memberId: item.memberId,
        };
        putmemberAddress(obj)
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("操作成功！");
              this.listads();
            }
          })
          .catch((res) => {
            console.log("请求出错");
          });
      }
    },
    resetForm() {
      this.$refs["ruleForm"].resetFields();
      this.ruleForm.procen = [];
      this.dialogVisible = false;
    },
    claickdialogVisible() {
      let that = this;
      that.titles = "新增收货地址";
      that.ruleForm.text = "";
      that.ruleForm.procen = [];
      that.ruleForm.phone = "";
      that.ruleForm.infos = "";
      this.id = "";
      this.memberId = "";
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.shiping {
  min-height: 1000px;
  background: #ffffff;
  margin-bottom: 70px;
  padding: 20px 25px;
  .shipList {
    width: 100%;
    padding: 24px 32px;
    margin-top: 17px;
    background: #ffffff;
    border: 2px solid #ececec;
    border-radius: 2px;
    position: relative;
    .guanbi {
      position: absolute;
      top: 14px;
      right: 16px;
      color: #999999;
      font-size: 15px;
      cursor: pointer;
    }
    .lsittop {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #535353;
      margin-bottom: 25px;
      .cards {
        display: inline-block;
        padding: 4px 7px;
        background: #ffaa45;
        border-radius: 2px;
        font-size: 12px;
        margin-left: 11px;
        color: #ffffff;
      }
    }
    .texts {
      margin-bottom: 15px;
      font-size: 14px;
      color: #535353;
      .textneme {
        display: inline-block;
        width: 80px;
        text-align: right;
        color: #818181;
      }
    }
    .bottoms {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .moren {
        font-size: 14px;
        color: #00a3e0;
        margin-right: 35px;
        cursor: pointer;
      }
      .edter {
        font-size: 14px;
        color: #00a3e0;
        cursor: pointer;
        .icons {
          width: 13px;
          height: 16px;
          margin-right: 4px;
        }
      }
    }
  }
}
</style>